import { getUser } from "@/models/requests.js";

const authenticationHelper = {
  async created() {
    if (!sessionStorage.getItem("token")) {
      if (this.$route.path !== "/") this.$router.push("/");
      return;
    }
    if (!this.$store.state.user.login) {
      const user = await getUser();
      await this.$store.dispatch("persistUser", user);
    }
  },
};

export { authenticationHelper };
