<template>
  <div class="home">
    <div class="focus">
      <div v-if="signedIn">
        <b-jumbotron
          :header="`Welcome ${signedIn}`"
          lead="What a day to get your side project done"
        >
          <b-button @click="goToRepos"> Get a New Code Review</b-button>
        </b-jumbotron>
        <div v-if="loading" class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <b-container class="bv-example-row">
            <b-row>
              <b-col :md="8">
                <h2>Revision Requests</h2>
                <div v-if="revisionRequests && revisionRequests.length > 0">
                  <b-list-group>
                    <b-list-group-item
                      v-for="request in revisionRequests"
                      :key="request.id"
                      class="d-flex justify-content-between align-items-center"
                    >
                      {{ request.pr.title }} from {{ request.repo }}
                      <b-badge variant="primary" pill>
                        {{
                          request.claimedBy
                            ? "revision in progress"
                            : "searching for reviewer"
                        }}
                      </b-badge>
                      <b-icon
                        icon="arrow-right-short"
                        font-scale="1.5"
                        @click="goToRequest(request)"
                      />
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div v-else>
                  No code reviews currently in progress.
                  <br />
                  <b-button size="lg" variant="primary">
                    Start a New Code Review
                  </b-button>
                </div>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <h4>Focus List</h4>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="outline-primary"
                      @click="$router.push('/repos')"
                    >
                      Add more repos
                    </b-button>
                  </b-col>
                </b-row>
                <div v-if="focusList && focusList.length > 0">
                  <b-row v-for="focusEntry in focusList" :key="focusEntry.id">
                    <b-col>
                      <router-link :to="getDetailURL(focusEntry)">
                        {{ focusEntry.name }}
                      </router-link>
                    </b-col>
                    <b-col>
                      <b-icon icon="x" @click="removeFocusItem(focusEntry)" />
                    </b-col>
                  </b-row>
                </div>
                <div v-else></div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <div v-else>
        <b-jumbotron
          fluid
          header="Write Better Code."
          lead="Get a code review from a senior software engineer directy on your github repository  in under 48 hours."
        >
          <b-button @click="login">Login with <b-icon-github /></b-button>
        </b-jumbotron>
        <b-container>
          <h2>All it takes is 4 easy steps</h2>

          <b-row>
            <b-col> Step 1 </b-col>
            <b-col> Step 2 </b-col>
            <b-col> Step 3 </b-col>
            <b-col> Step 4 </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { authenticationHelper } from "@/mixins/authentication.js";

export default {
  name: "Home",
  mixins: [authenticationHelper],
  data: () => {
    return {
      loading: false,
    };
  },
  async mounted() {
    console.log(process.env.VUE_APP_TEST);
    console.log(process.env.NODE_ENV);
    if (this.signedIn) {
      this.loading = true;
      await this.$store.dispatch("getReviewRequests");

      await this.$store.dispatch("getFocusList");
      this.loading = false;
    }
  },
  computed: {
    signedIn() {
      return sessionStorage.getItem("login");
    },
    focusList() {
      return this.$store.state.focus;
    },
    revisionRequests() {
      return this.$store.state.reviewRequests;
    },
  },
  methods: {
    /**
     * Dispatches github login
     */
    login() {
      const clientID = process.env.VUE_APP_GITHUB_CLIENT;
      window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&scope=repo%20repo:invite%20read:user%20user:email&state=yeeeyee`;
    },
    /**
     *
     */
    getDetailURL(repo) {
      return `/user/${repo.owner.login}/repo/${repo.name}`;
    },
    /**
     *
     */
    goToRepos() {
      this.$router.push("/repos");
    },
    /**
     * removes item from focus list
     */
    removeFocusItem(repo) {
      this.$store.dispatch("removeFocusItem", repo);
    },
    /**
     * Redirects to request
     */
    goToRequest(request) {
      this.$router.push(`/requests/${request.createdAt}`);
    },
  },
  watch: {
    async signedIn(oldValue, newValue) {
      if (!oldValue && newValue) {
        this.$store.dispatch("getFocusList");
        this.$store.dispatch("getReviewRequests");
      }
    },
  },
};
</script>
