<template>
  <div id="repos">
    <div v-if="repos.length > 0">
      <b-jumbotron>
        <h1>Pick a repository to focus on</h1>
      </b-jumbotron>

      <div class="list-box">
        <div
          class="row list-row"
          v-for="(repo, index) in filteredRepos"
          :key="repo.name + index"
        >
          <div class="col childSpacingRight">
            <b-button
              @click="
                isRepoFocused(repo)
                  ? $store.dispatch('removeFocusItem', repo)
                  : $store.dispatch('addToFocus', repo)
              "
            >
              <b-icon :icon="isRepoFocused(repo) ? 'star-fill' : 'star'" />
            </b-button>
            <b-button @click="goToDetailPage(repo.repo)">
              <b-icon icon="arrow-right-short" />
            </b-button>
          </div>
          <div class="col" @click="goToDetailPage(repo.repo)">
            <div class="repo-name">{{ repo.name }}</div>
            <i class="repo-description">{{ repo.description }}</i>
          </div>
          <div class="col childSpacingRight">
            <b-badge pill variant="primary">{{ repo.visibility }}</b-badge>
            <b-badge pill variant="primary"> {{ repo.owner }} </b-badge>
          </div>
          <div class="col" @click="goToDetailPage(repo.repo)">
            <b-button size="lg"> Get a code review </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <div v-else>Create your own Repo Form</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.childSpacingRight {
  > *:first-child {
    margin-right: 1rem;
  }
}

.list-row {
  border: 1px solid;
  padding: 3rem 2rem;
  border-radius: 0.42rem;
  margin: 1rem 2rem;

  div.child {
    margin-right: 1rem;
  }
}

.repo-name {
  font-size: 22px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.repo-visibility {
  width: fit-content;
  border: 1px solid black;
  border-radius: 2rem;
  padding: 0.42rem;
}
</style>
<script>
/**
 * Todo- Finish the design by separating two routes that pass different actions and call this
 */

import { getRepos } from "@/models/requests.js";
import { authenticationHelper } from "@/mixins/authentication.js";

export default {
  name: "Repos",
  mixins: [authenticationHelper],
  data: () => {
    return {
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "action", label: "" },
        { key: "name", label: "name", sortable: true },
        { key: "owner", label: "owner", sortable: true },
        { key: "visibility", label: "visibility" },
        { key: "description", label: "description" },
      ],
      repos: [],
      filteredRepos: [],
      loading: false,
      page: 1,
      pagination: 30,
      filter: {
        privacy: null,
      },
    };
  },
  async created() {
    this.loading = true;
    this.repos = await getRepos();
    this.filteredRepos = this._filterRepos();
    this.loading = false;
  },
  computed: {},
  methods: {
    /**
     * Is repo focused
     */
    isRepoFocused(repo) {
      console.log(repo.id);
      console.log(this.$store.state.focus);
      const repoFilter = this.$store.state.focus.filter((focusRepo) => {
        if (focusRepo.id === repo.id) {
          return focusRepo;
        }
      });
      console.log(repoFilter);
      console.log(repoFilter.length > 0);
      return repoFilter.length > 0;
    },
    /**
     * Returns boolean if the current user is owner of repo
     */
    isCurrentUserOwner(owner) {
      return sessionStorage.getItem("login") === owner;
    },

    filterBy(attribute, setting) {
      const filters = this.filter;
      filters[attribute] = setting;
      this.filter = filters;
      this.filteredRepos = this._filterRepos();
    },
    async goToDetailPage(repo, addToFocus = false) {
      /**
        Reinstate this once we need the invitation

        const repoFetch = await fetch(
        `http://localhost:4200/request-review?repo=${repo.name}&owner=${repo.owner.login}`
      );
      const repoJSON = await repoFetch.json();
      console.log(repoJSON);
      */

      if (addToFocus) await this.$store.dispatch("addToFocus", repo);

      this.$router.push({
        name: "Repo",
        params: { repo: repo.name, user: repo.owner.login },
      });
    },
    _filterRepos() {
      return this.repos.map((repo) => {
        let shouldReturnThis = false;

        if (this.filter.privacy) {
          if (this.filter.privacy === "private" && repo.private) {
            shouldReturnThis = true;
          }
          if (this.filter.privacy === "public" && !repo.private) {
            shouldReturnThis = true;
          }
        } else {
          shouldReturnThis = true;
        }

        if (shouldReturnThis) {
          return {
            id: repo.id,
            name: repo.name,
            owner: repo.owner.login,
            visibility: repo.private ? "private" : "public",
            description: repo.description,
            repo,
          };
        } else return;
      });
    },
  },
};
</script>
