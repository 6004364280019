<template>
  <div>
    <div>Congrats {{ codeReviewPurchaseInfo.purchaser }}</div>
    <div>
      A review for
      <a :href="codeReviewPurchaseInfo.pr.html_url" target="_blank">
        {{ codeReviewPurchaseInfo.pr.title }}
      </a>
      from {{ codeReviewPurchaseInfo.repo }} has been confirmed.
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
<script>
import { createReviewRequest } from "@/models/requests.js";

export default {
  name: "PurchaseSuccess",
  data: () => {
    return {
      codeReviewPurchaseInfo: {},
    };
  },
  computed: {
    url() {
      return this.codeReviewPurchaseInfo
        ? this.codeReviewPurchaseInfo.pr.html_url
        : "";
    },
  },
  async mounted() {
    if (sessionStorage.getItem("codeReviewPurchaseInfo")) {
      const codeReviewPurchaseInfo = JSON.parse(
        sessionStorage.getItem("codeReviewPurchaseInfo")
      );
      this.codeReviewPurchaseInfo = codeReviewPurchaseInfo;

      // persist the data
      const reviewRequest = await createReviewRequest(codeReviewPurchaseInfo);
      if (reviewRequest.message === "success")
        sessionStorage.removeItem("codeReviewPurchaseInfo");
    } else {
      this.$router.push("/");
    }
  },
};
</script>
