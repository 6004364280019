import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/pages/Home.vue";
import CompleteSignup from "@/pages/CompleteSignup.vue";
import Repos from "@/pages/Repos.vue";
import Repo from "@/pages/Repo.vue";
import PurchaseSuccess from "@/pages/PurchaseSuccess.vue";
import PurchaseCancelled from "@/pages/PurchaseCancelled.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/repos",
    name: "Repos",
    component: Repos,
  },
  {
    path: "/complete-login",
    name: "CompleteSignup",
    component: CompleteSignup,
  },
  {
    path: "/user/:user/repo/:repo",
    name: "Repo",
    component: Repo,
  },
  {
    path: "/purchase-success",
    name: "PurchaseSuccess",
    component: PurchaseSuccess,
  },
  {
    path: "/purchase-cancelled",
    name: "PurchaseCancelled",
    component: PurchaseCancelled,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
