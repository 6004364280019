<template>
  <div>Purchase Cancelled</div>
</template>

<style lang="scss" scoped></style>
<script>
export default {
  name: "PurchaseCancelled",
  async mounted() {
    // persist the data
  },
};
</script>
