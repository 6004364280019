<template>
  <b-nav id="nav">
    <b-nav-item> <router-link to="/">Home</router-link> </b-nav-item>
    <b-nav-item v-if="signedIn">
      <router-link to="/repos">Repos</router-link>
    </b-nav-item>
    <b-nav-item v-if="signedIn">
      <a @click="logout" class="router-link-active">Logout</a>
    </b-nav-item>
    <b-nav-item v-else>
      <a @click="login" class="router-link-active">
        Login with <b-icon-github /> </a
    ></b-nav-item>
  </b-nav>
</template>
<style lang="scss" scoped>
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  a.router-link-active {
    font-weight: bold;
    color: #2c3e50;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<script>
export default {
  name: "Navbar",
  methods: {
    logout() {
      sessionStorage.clear();
      window.location.href = "/";
    },
    login() {
      const clientID = process.env.VUE_APP_GITHUB_CLIENT;
      window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&scope=repo%20repo:invite%20read:user%20user:email&state=yeeeyee`;
    },
  },
  computed: {
    signedIn() {
      return sessionStorage.getItem("login") || this.$store.state.user.login;
    },
  },
};
</script>
