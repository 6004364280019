import Vue from "vue";
import Vuex from "vuex";
import {
  setFocusList,
  getFocusList,
  getReviewRequests,
} from "@/models/requests.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    focus: [],
    reviewRequests: [],
  },
  mutations: {
    persistUser(state, payload) {
      state.user = payload.user;
    },
    addToFocus(state, payload) {
      state.focus.push(payload);
    },
    setFocus(state, payload) {
      state.focus = payload;
    },
    setReviewRequests(state, payload) {
      state.reviewRequests = payload;
    },
  },
  actions: {
    persistUser(context, user) {
      context.commit("persistUser", user);
    },
    async addToFocus(context, repo) {
      // if duplicate don't add
      const duplicate =
        context.state.focus.filter((focusRepo) => focusRepo.id === repo.id)
          .length > 0;
      if (!duplicate) {
        await context.commit("addToFocus", repo);
        await setFocusList(context.state.user.login, context.state.focus);
      }
    },
    async getFocusList(context) {
      const owner = sessionStorage.getItem("login");
      const data = await getFocusList(owner);
      context.commit("setFocus", data.focus);
    },
    async getReviewRequests(context) {
      const user = sessionStorage.getItem("login");
      const data = await getReviewRequests(user);
      context.commit("setReviewRequests", data.reviewRequests);
    },
    async removeFocusItem(context, repo) {
      const newFocusList = context.state.focus.filter(
        (focusRepo) => focusRepo.id != repo.id
      );
      await context.commit("setFocus", newFocusList);
      await setFocusList(context.state.user.login, context.state.focus);
    },
  },
});
